import React from "react"
import Menu from "./src/components/Menu"
import PageWrapper from "./src/components/PageWrapper"
import store from "./src/components/Store"
import smoothscroll from "smoothscroll-polyfill"
import "fullscreen-polyfill"
import { LazyMotion, domAnimation } from "framer-motion"

import "./src/styles/global.css"

smoothscroll.polyfill()

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}

export const onRouteUpdate = () => {
  const { getState } = store
  const { setMenuIsOpen } = getState()
  setMenuIsOpen(false)
}

export function wrapPageElement({ element, ...props }) {
  return (
    <LazyMotion features={domAnimation}>
      <Menu home={props?.props?.path === "/"}></Menu>
      <PageWrapper {...props.props}>{element}</PageWrapper>
    </LazyMotion>
  )
}
