import { AnimatePresence } from "framer-motion"
import React, { useEffect } from "react"

export default function PageWrapper({ children }) {
  return (
    <div id="PageWrapper">
      <div className="custom_container" />

      <>
        <title>Copenhagen Visual</title>
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="/default-favicon.ico"
        />
        <meta property="og:site_name" content="Copenhagen Visual" />
        <meta property="og:title" content="Copenhagen Visual" />
        <meta property="og:url" content="https://copenhagenvisual.com/" />
        <meta property="og:type" content="website" />
        <meta itemProp="name" content="Copenhagen Visual" />
        <meta itemProp="url" content="https://copenhagenvisual.com/" />
        <meta name="twitter:title" content="Copenhagen Visual" />
        <meta name="twitter:url" content="https://copenhagenvisual.com/" />
        <meta name="twitter:card" content="summary" />
        <meta name="description" content="" />
      </>
      <AnimatePresence initial={false}>{children}</AnimatePresence>
    </div>
  )
}
