import React from "react"
import { css } from "@emotion/react"
import cn from "classnames"
export default function Circle({
  independent,
  className = "",
  scale = 1,
  r = 24,
  stroke = "#fff",
  isOpen,
  home,
}) {
  const p = Math.PI * 2 * r
  const D = r * 2 + 1
  return (
    <svg
      width={D}
      height={D}
      className={cn([
        className,
        {
          group: independent,
        },
      ])}
      css={css`
        transform: scale(${scale});
      `}
      viewBox={`0 0 ${D} ${D}`}
    >
      <circle
        cx="50%"
        cy="50%"
        r={r}
        stroke={isOpen ? "#000" : home ? stroke : "#000"}
        strokeWidth={1}
        className="opacity-25"
        strokeDasharray={`${4 * D} ${4 * D}`}
        strokeDashoffset={0}
        fill="transparent"
      />
      <circle
        cx="50%"
        strokeDashoffset={p}
        strokeDasharray={`${p} ${p}`}
        cy="50%"
        className="tablet:hidden transform  rotate-90 group-hover:rotate-270 group-hover:stroke-offset-0 duration-700  origin-center"
        r={r}
        stroke={isOpen ? "#000" : home ? stroke : "#000"}
        strokeWidth={1}
        fill="transparent"
      />
    </svg>
  )
}
