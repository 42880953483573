import { motion } from "framer-motion"
import React, { useState } from "react"
import tw from "twin.macro"
import { css } from "@emotion/react"
import Circle from "../Circle"
import { Link } from "gatsby"
import Navigation from "./navigation"
import useStore from "../Store"

export default function Menu({ home }) {
  const menuIsOpen = useStore((state) => state.menuIsOpen)
  const setMenuIsOpen = useStore((state) => state.setMenuIsOpen)

  return (
    <>
      {" "}
      <div
        id="Menu"
        className="p-4 md:py-8 md:px-8 fixed-top  z-50 "
        css={css`
          padding-top: 16px;
          padding-bottom: 16px;
          @media (min-width: 1024px) {
            padding-top: 32px;
            padding-bottom: 32px;
          }
          transform: translate3d(0, 0, 0);
        `}
      >
        <div className="flex justify-between items-center ">
          <MenuInfo
            isOpen={menuIsOpen}
            home={home}
            toggle={() => setMenuIsOpen(!menuIsOpen)}
          />
          <div></div>
          <MenuButton
            isOpen={menuIsOpen}
            home={home}
            toggle={() => setMenuIsOpen(!menuIsOpen)}
          />
        </div>
      </div>
      <Navigation
        isOpen={menuIsOpen}
        toggle={() => setMenuIsOpen(!menuIsOpen)}
      />
    </>
  )
}

const MenuInfo = ({ isOpen, home }) => {
  return (
    <div className="flex items-center flex-grow">
      {" "}
      <motion.div
        initial={{ opacity: 0, scaleX: 0 }}
        exit={{ opacity: 0, scaleX: 0 }}
        animate={{
          opacity: 1,
          scaleX: 1,
          transition: {
            duration: 0.7,
            ease: "easeOut",
          },
        }}
        className={`h-px w-5 origin-left mr-3 ${
          isOpen ? "bg-black" : home ? "bg-white" : "bg-black"
        }`}
      ></motion.div>{" "}
      <motion.div
        initial={{ opacity: 0, y: 5 }}
        exit={{ opacity: 0, y: -5 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { delay: 0.3, duration: 0.7, ease: "easeOut" },
        }}
        className={`text-xs ${
          isOpen ? "text-black" : home ? "text-white" : "text-black"
        }`}
      >
        <Link to="/">HOME</Link>
      </motion.div>
    </div>
  )
}

function MenuButton({ isOpen, toggle, home }) {
  const [hover, setHover] = useState(false)

  const posBottom = {
    y: "110%",
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  }

  const active = {
    y: 0,
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  }
  const posTop = {
    y: "-110%",
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  }

  const MenuV = {
    false: {
      false: active,
      true: posTop,
    },
    true: {
      false: posTop,
      true: posTop,
    },
  }
  const OpenV = {
    false: {
      false: posBottom,
      true: active,
    },
    true: {
      false: posTop,
      true: posTop,
    },
  }
  const CloseV = {
    false: {
      false: posBottom,
      true: posBottom,
    },
    true: {
      false: active,
      true: active,
    },
  }

  return (
    <div className="flex-grow flex justify-end">
      {" "}
      <motion.button
        className="focus:outline-none group  flex items-center"
        onClick={toggle}
        onHoverStart={() => {
          setHover(true)
        }}
        onHoverEnd={() => {
          setHover(false)
        }}
      >
        <div
          className={`hidden md:block text-xs ${
            isOpen ? "text-black" : home ? "text-white" : "text-black"
          }`}
          css={css`
            position: relative;
            overflow: hidden;
            height: 1rem;
            flex-grow: 1;
            width: 150px;
            /* @lg */
            @media (min-width: 1024px) {
              width: 4vw;
            }

            span {
              ${tw`block  absolute uppercase top-0 right-0 `}
              white-space: nowrap;
              transition-property: transform, opacity;
              transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
              transition-duration: 0.4s;
            }
          `}
        >
          <motion.span initial={active} animate={MenuV[isOpen][hover]}>
            Menu
          </motion.span>
          <motion.span initial={posBottom} animate={OpenV[isOpen][hover]}>
            Open
          </motion.span>

          <motion.span initial={posBottom} animate={CloseV[isOpen][hover]}>
            Close
          </motion.span>
        </div>
        <div className="ml-3 relative">
          <Dots isOpen={isOpen} home={home} />
          <Circle isOpen={isOpen} home={home} />
        </div>
      </motion.button>
    </div>
  )
}

function Dots({ isOpen, home }) {
  return (
    <div
      className={`absolute`}
      css={css`
        width: 50px;
        height: 50px;
        transform-origin: "center center";

        /* @lg */

        transform: ${isOpen ? "rotate(90deg)" : "rotate(0deg)"};
        @media (max-width: 767px) {
          transition: transform 400ms ease-in-out;
        }
        i {
          position: absolute;
          display: block;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
          transform: translate(-50%, -50%) translateX(0px) scale(1);
          /* @lg */
          @media (min-width: 1024px) {
            .group:hover & {
              transform: translate(-50%, -50%) translateX(0px) scale(2);
            }
          }
          &:nth-of-type(1) {
            transform: translate(-50%, -50%) translateX(-7px);
          }
          &:nth-of-type(3) {
            transform: translate(-50%, -50%) translateX(7px);
          }
        }
      `}
    >
      <i
        className={`${isOpen ? "bg-black" : home ? "bg-white" : "bg-black"}`}
      />
      <i
        className={`${isOpen ? "bg-black" : home ? "bg-white" : "bg-black"}`}
      />
      <i
        className={`${isOpen ? "bg-black" : home ? "bg-white" : "bg-black"}`}
      />
    </div>
  )
}
