import vanila from "zustand/vanilla"
import create from "zustand"
export const store = vanila((set, get) => ({
  title: null,
  menuIsOpen: false,
  setTitle: (title) => set({ title }),
  setMenuIsOpen: (menuIsOpen) => set({ menuIsOpen }),
}))

export default create(store)
