import { AnimatePresence, motion } from "framer-motion"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { css } from "@emotion/react"

const links = {
  initial: {
    y: "100%",
    transition: {
      duration: 0.2,
      ease: "circIn",
    },
  },
  rest: {
    y: 0,
    transition: {
      ease: "circOut",
      duration: 0.3,
    },
  },
}
const container = {
  initial: {
    y: "-100%",
    transition: {
      duration: 0.3,
      ease: "circIn",
      delay: 0.2,
    },
  },
  rest: {
    y: 0,
    transition: {
      ease: "circOut",
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
}

export default function Navigation({ isOpen, toggle }) {
  const [isHovered, setIsHovered] = useState(null)

  const {
    prismicAboutUs,
    prismicWork,
    prismicColor,
    prismicContactUs,
    prismicJob,
  } = useStaticQuery(graphql`
    {
      prismicAboutUs {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicWork {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicColor {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicContactUs {
        data {
          title {
            text
          }
        }
        uid
      }
      prismicJob {
        data {
          title {
            text
          }
        }
        uid
      }
    }
  `)

  return (
    <motion.div
      initial={"initial"}
      animate={isOpen ? "rest" : "initial"}
      variants={container}
      className="fixed-fullscreen z-40 bg-white text-black flex items-center justify-center px-4 lg:px-8"
    >
      <div
        className="lg:space-x-20 flex tablet:flex-col tablet:space-y-7   items-center "
        css={css`
          @media (max-width: 850px) and (orientation: landscape) {
            justify-content: space-around;
            height: 70vh;
            margin-top: 10vh;
            > div {
              margin-top: 10px !important;
              margin-bottom: 10px !important;
            }
          }
        `}
      >
        {[
          prismicWork,
          prismicColor,
          prismicAboutUs,
          prismicJob,
          prismicContactUs,
        ].map(
          (page, i) =>
            page && (
              <div key={i} className="overflow-hidden">
                <motion.div
                  variants={links}
                  onHoverStart={() => {
                    setIsHovered(i)
                  }}
                  onHoverEnd={() => {
                    setIsHovered(null)
                  }}
                >
                  <Link
                    to={`/${page.uid}/`}
                    onClick={(e) => {
                      e.preventDefault()
                      toggle()
                      setTimeout(() => {
                        navigate(`/${page.uid}/`)
                      }, 200)
                    }}
                    className={`${
                      isOpen ? "after:bg-black" : "after:bg-white"
                    }`}
                    css={css`
                      font-family: "Helvetica Neue";
                      font-size: 30px;
                      padding: 8px 0;
                      letter-spacing: 0.28px;
                      text-transform: uppercase;
                      opacity: ${isHovered === i || isHovered === null
                        ? "1"
                        : "0.5"};
                      transition: opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)
                        0s;
                      &:after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;

                        transform: ${isHovered === i
                          ? "scaleX(1)"
                          : "scaleX(0)"};
                        transform-origin: ${isHovered === i
                          ? "center left"
                          : "center right"};
                        transition: transform 0.2s
                          cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
                      }
                    `}
                  >
                    {page.data.title.text}
                  </Link>
                </motion.div>
              </div>
            )
        )}
      </div>
    </motion.div>
  )
}
